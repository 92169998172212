.home {
    padding: 3rem;
    padding-bottom: 0;
    background-color: var(--null-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    height: 100%;
    min-height: 90vh;
    width: 100%;
    max-width: 1113px;
    min-width: 300px;
    background-color: var(--background-color);
    border-radius: 3px;
    padding: 3rem 10rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    .container {
        padding: 2rem 7rem 0;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 2rem 5rem 0;
    }
}

@media screen and (max-width: 540px) {
    .home {
        padding: 0;
    }
}