.section-wrapper {
    width: 100%;
    display: flex;
    margin: 6rem 0 4rem;
}

.section-title {
    color: var(--primary-color);
    font-size: 2.3rem;
    position: relative;
    padding: 0 1.4rem;
    white-space: nowrap;
    
}

.divider {
    width: 100%;
    height: 1.4rem;
    border-bottom: 0.5px solid var(--primary-color);
}

@media screen and (max-width: 1024px) {
    .section-title {
        font-size: 1.6rem;
    }

    .divider {
        height: 1rem;
    }

    .section-wrapper {
        margin: 3.5rem 0 2rem;
    }
}

@media screen and (max-width: 768px) {
    .section-title {
        font-size: 1.3rem;
    }

    .divider {
        height: 0.8rem;
    }

    .section-wrapper {
        margin: 2.5rem 0 0;
    }
}

@media screen and (max-width: 540px) {
    .section-title {
        font-size: 1.1rem;
    }

    .divider {
        height: 0.7rem;
    }

    .section-wrapper {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 420px) {
    .section-title {
        font-size: 1rem;
    }

    .divider {
        height: 0.6rem;
    }

    .section-wrapper {
        margin: 1rem 0 0;
    }
}