.section-items-wrapper {
    margin:  4rem 0 2rem;
    padding: 1rem 0rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-content: space-between;
    grid-gap: 30%;
}

.extra-padding {
    margin: 4rem 0 22rem;
}

@media screen and (max-width: 1549px) {
    .section-items-wrapper {
        margin:  4rem 0 2rem;
        grid-gap: 30%;
    }

    .extra-padding {
        margin: 4rem 0 15rem;
    }
}

@media screen and (max-width: 768px) {
    .section-items-wrapper {
        margin:  4rem 0 1rem;
        grid-gap: 20%;
    }
    .extra-padding {
        margin: 4rem 0 7rem;
    }
}

@media screen and (max-width: 630px) {
    .section-items-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20vw;
        margin-bottom:  2rem;
    }
}