
.footer {
    border-top: 0.5px solid var(--primary-color);
    width: 100%;
    height: 23rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 3rem;
    color: var(--primary-color);
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer .back-to-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 10px;
    gap: 0.4rem;
}

.icon-arrow {
    width: 90px;
}

.footer > p {
    white-space: nowrap;
    padding-top: 0.3rem;
    font-size: 13px;

}

.footer .name {
    font-weight: bold;
}

.footer .icons {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
     color: var(--primary-color);
}

.footer .icon {
    width: 20px;
}

@media screen and (max-width: 540px) {
    .footer {
        height: 21.5rem;
        padding: 1.5rem;
    }
    .footer > p {
        padding-top: 0.3rem;
        font-size: 11px;
    }

    .footer .back-to-top {
        margin-bottom: 1.3rem;
    }
}