.loader {
    border: 4px solid var(--background-color);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    animation: spin 1s linear infinite;
    position: absolute;
    z-index: 1;
    left: calc(18rem - 21px - 7px);
    top: 1.8rem;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 826px) {
    .loader {
      width: 19px;
      height: 19px;
      left: calc(12rem - 21px - 4px);
      top: 1.4rem;
    }
  }

  @media screen and (max-width: 630px) {
    .loader {

      top: 1.5rem;
    }
  }
  
  