header {
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button {
    background-color: transparent;
    border: none;
    color: var(--primary-color);
    font-size: 44px;
    cursor: pointer;
}

button:hover {
    filter: brightness(1.1);
}

.selected {
    border-bottom: 1px solid var(--primary-color);
}

.info-btn {
    margin-right: 5vw;
}

@media screen and (max-width: 1024px) {
    .info-btn {
        margin-right: 2vh;
    }

    button {
        font-size: 30px;
    }
}

@media screen and (max-width: 768px) {
    button {
        font-size: 24px;
    }

    .info-btn {
        margin-right: 0.8rem;
    }
}

@media screen and (max-width: 540px) {
    button {
        font-size: 20px;
    }

    .info-btn {
        margin-right: 1rem;
    }
}

@media screen and (max-width: 420px) {
    button {
        font-size: 16px;
    }

    .info-btn {
        margin-right: 1rem;
    }

    header {
        margin-bottom: 1.3rem;
    }
}