.detail-container {
    width: 100%;
    max-width: 700px;
    min-width: 44rem;
    color:  var(--background-color);
    background-color:  var(--primary-color);
    padding-top: 0.2rem;
    margin: 3em 3.3rem;
}

.close-btn {
    position: absolute;
    right: 0;
    top: 0.1rem;
    margin: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    color: var(--background-color);
}

.img-wrapper {
    height: 250px;
    overflow-y: hidden;
}

.detail-section-item-img {
    width: 100%;
    border-radius: 3px;
    transform: translatey(-25%);
}

.detail-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.markdown-wrapper {
    padding: 1.5rem 0;
}

@media screen and (max-width: 900px) {
    .detail-container {
        min-width: auto;
    }
}

@media screen and (max-width: 540px) {
    .img-wrapper {
        height: 200px;
    }
    
    .detail-section-item-img {
        height: 350px;
    } 
}

@media screen and (max-width: 420px) {
    .img-wrapper {
        height: 150px;
    }
    
    .detail-section-item-img {
        height: 270px;
    } 
}


::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
background: var(--primary-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
background: var(--background-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}