.email-submit-container {
    height: 3rem;
    margin-bottom: 3rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.email-submit-container form {
    position: relative;
}

.check-icon {
    color: green;
    position: absolute;
    z-index: 1;
    left: calc(18rem - 21px - 3px);
    top: 2rem;
}

.email-submit-container form p {
    padding-bottom: 0.2rem;
}

.email-submit-container input {
    height: 2rem;
    width: 18rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
}

.email-submit-container button {
    font-size: 1rem;
    padding: 0.4rem 2rem;
    background-color: var(--null-color);
}

.email-submit-container input:focus {
    border: none;
    outline: none;
}

.email-submit-container input:active {
    border: none;
}

@media screen  and (max-width: 826px) {
    .email-submit-container input {
        height: 1.8rem;
        width: 12rem;
    }

    .email-submit-container button {
        font-size: 0.8rem;
        padding: 0.4rem 1.2rem;
    }

    .email-submit-container form p {
        padding-bottom: 0.1rem;
        font-size: 0.8rem;
    }

    .check-icon {
        left: calc(12rem - 21px - 1px);
        top: 1.6rem;
    }
}


@media screen  and (max-width: 630px) {
    .email-submit-container input {
        height: 1.8rem;
        width: 12rem;
        border: 1px solid green;
        margin-right: 0rem;
        margin-bottom: 0.3rem;

    }

    .email-submit-container button {
        font-size: 0.8rem;
        padding: 0.4rem 1.2rem;
    }

    .email-submit-container form p {
        padding-bottom: 0.1rem;
        font-size: 0.8rem;
    }

    .email-submit-container {
        height: 6rem;
        margin-bottom: 1.8rem;
    }

    .email-submit-container form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1rem;
    }

    .check-icon {
        left: calc(12rem - 21px - 1px);
        top: 1.6rem;
    }
}