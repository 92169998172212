@font-face {
    font-family: 'Apple SD';
    src: local('Apple'), url(./fonts/AppleSDGothicNeo-Regular.otf) format('opentype');
  }

body, #root {
    /* Theme: colors */
    --null-color: #1E1E1E;
    --background-color: #2E2F37;
    --primary-color: #EEE0E0;
    --link-color: #465471;
    --link-color-dark: #d2baed;
    background-color: var(--null-color);
}

* {
    box-sizing: border-box;
    font-family: Apple SD, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;;
    padding: 0;
    margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}