.section-item-container {
    display: flex;
    justify-content: center;
}

.section-item {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 1rem;

}

@media screen and (max-width: 540px) {
    .section-item {
        min-width: 200px;
        width: 230px;
    }
}

.section-item.selected {
    border: 5px solid green;
}

.section-item:hover {
    filter: brightness(1.1);
}

.terminated, .terminated:hover {
    filter: brightness(0.5)
}

.section-item-img {
    width: 100%;
    height: 90%;
}

.section-items-label {
    font-size: 17px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1.2rem 0;
}