.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.45) !important;
}

main {
    color: var(--primary-color);
    width: 100%;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
}

.hero-title {
    font-size: 1.6rem;
    max-width: 40rem;
    margin-bottom: 1.5rem;
    padding: 2rem 0 0.5rem;
}

@media screen and (max-width: 540px) {
    main {
        color: var(--primary-color);
        margin-bottom: 3rem;
    }

    .hero-title {
        font-size: 1.5rem;
    }
}